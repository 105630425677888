import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import topBannerImg from "../images/top-banner-icon.png"
// import productDevlopmentImg from "../images//product-development-img1.png"
import specialiteWebsite from "../images/specialite_maison_website.png"
import specialiteMobile from "../images/specialite_maison_mobile.png"
import specialiteDesktop from "../images/specialite_maison_desktop.png"
import specialiteBackend from "../images/specialite_maison_backend.png"
// import elctronJs from "../images/electron-js.png"
// import nodeJs from "../images/node-js.png"
// import reactJs from "../images/react-js.png"
// import javascript from "../images/javascript.png"
// import mongoDb from "../images/mongo-db.png"
import profilePic from "../images/profile-pic.jpg"
import GrowImg from "../images/grow_portfolio.jpg";
import BuzziroImg from "../images/buzziro_portfolio.jpg";
import RenraccoonImg from "../images/renraccoon_portfolio.jpg";
import BukitvistaImg from "../images/bukitvista_portfolio.jpg";
import portfolioImg1 from "../images/portfolio-img-1.jpg"
import portfolioImg2 from "../images/portfolio-img-2.jpg"
import "../styles/global.scss"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated, useSprings } from "react-spring"
import SplitText from "react-pose-text"
import Tilt from "react-tilt"
const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.1,
]
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const IndexPage = ({ isVisible }) => {
  const charPoses = {
    exit: { opacity: 0, y: 20 },
    enter: {
      opacity: 1,
      y: 0,
      delay: ({ charIndex }) => charIndex * 30,
    },
  }
  const [springs, sets, stop] = useSprings(2, index => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  const [props, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))
  const { o, xyz } = useSpring({
    from: { o: 0, xyz: [0, 0, 0], color: "red" },
    o: 1,
    xyz: [10, 20, 5],
  })
  const data = useStaticQuery(graphql`
    query MyQuery {
      topBannerImage: file(relativePath: { eq: "top-banner-icon.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      productDevelopmentImage: file(
        relativePath: { eq: "product-development-img1.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteWebsiteImage: file(
        relativePath: { eq: "specialite_maison_website.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteMobileImage: file(
        relativePath: { eq: "specialite_maison_mobile.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteDesktopImage: file(
        relativePath: { eq: "specialite_maison_desktop.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      specialiteBackendImage: file(
        relativePath: { eq: "specialite_maison_backend.png" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }

      profilePicImage: file(relativePath: { eq: "profile-pic.jpg" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }

      portfolioImg1: file(relativePath: { eq: "portfolio-img-1.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      portfolioImg2: file(relativePath: { eq: "portfolio-img-1.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  //  const pageQuery = graphql`
  //   query HeaderImageQuery {
  //     headerImage: imageSharp(id: { regex: "/index/" }) {
  //       sizes(maxWidth: 1240 ) {
  //         ...GatsbyImageSharpSizes
  //       }
  //     }
  //   }
  // `

  return (
    <Layout>
      <SEO title="Albetrios - Software development company" />

      <section className="wrapper top_banner">
        <div className="container">
          <div className="wrapper top_banner_slider">
            <div className="wrapper colwrap">
              <div className="textwrap">
                <h1 className="h1">
                  <SplitText
                    initialPose="exit"
                    pose="enter"
                    charPoses={charPoses}
                  >
                    Delivering with excellence, ready to build
                    your next #desktop #web #mobile app
                  </SplitText>
                </h1>
              </div>
              <div className="imgwrap">
                <Img fluid={data.topBannerImage.childImageSharp.fluid} />
                {/* <img src={topBannerImg} alt="" /> */}
              </div>
            </div>
          </div>
          <a href="#development">
            <abbr className="down_arrow"></abbr>
          </a>
        </div>
      </section>
      <section className="wrapper banner_copy_text" id="development">
        <div className="container">
          <h4 className="wrapper h4">
            We combine the advice of business and technology of last generation,
            to create solutions to the extent of their world and that makes us
            unique.
          </h4>
        </div>
      </section>

      <section className="wrapper product_development">
        <div className="container">
          <div className="wrapper two_col_main">
            <span className="wrapper services_heading_text">
              Product Development
            </span>
            <div className="wrapper two_col">
              <div className="imgwrap">
                <animated.div
                  className="card"
                  onMouseMove={({ clientX: x, clientY: y }) =>
                    set({ xys: calc(x, y) })
                  }
                  onMouseLeave={() => set({ xys: [0, 0, 1] })}
                  style={{ transform: props.xys.interpolate(trans) }}
                >
                  <Img
                    fluid={data.productDevelopmentImage.childImageSharp.fluid}
                  />
                  {/* <img src={productDevlopmentImg} alt="" /> */}
                </animated.div>
              </div>
              <div className="textwrap">
                <span>Product Development</span>
                <h3 className="h3">
                  Turn your ideas into a digital product. <br/>
                  We will help you win!
                </h3>
                <p className="wrapper">
                  Looking for a team to build a web, mobile or desktop
                  application based on your idea? Our experienced
                  teams of Developers, UI/UX Designers and QA
                  Engineers will help you to be successful.
                </p>
                {/* Read more commented */}
                {/* <a href="javascript:void(0)">
                  <span className="btn2">Read more</span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper specialite_maison">
        <div className="container">
          <h2 className="wrapper h2">How we can help you?</h2>
        </div>
        <div className="container">
          {/* konw more commented */}
          {/* <a href="javascript:void(0)">
            <span className="btn2">Know more</span>
          </a> */}

          <ul className="wrapper services_list">
            {/* {items.map(i => (
              <animated.div
                key={i}
                className="script-bf-box"
                style={{ transform: radians.interpolate(interp(i)) }}
              >
                <li>
                  <div className="maisonimghide">
                    <img src={specialiteWebsite} alt="" />
                  </div>
                  <div className="maisonimgshow">
                    <img src={specialiteWebsite} alt="" />
                  </div>
                  <h5 className="h5">Web Application Development</h5>
                  <p className="maisonpara">
                    We build highly scalable platforms and well-tested products
                    that are ready to handle million of users on a daily basis.
                  </p>
                  <span className="vertical_text">website</span>
                </li>
              </animated.div>
            ))} */}
            <li>
              <div className="maisonimghide">
                {/* <Img
                  fluid={data.specialiteWebsiteImage.childImageSharp.fluid}
                /> */}
                <img src={specialiteWebsite} alt="" />
              </div>
              <div className="maisonimgshow">
                {/* <Img
                  fluid={data.specialiteWebsiteImage.childImageSharp.fluid}
                /> */}
                <img src={specialiteWebsite} alt="" />
              </div>
              <h5 className="h5">Web Application Development</h5>
              <p className="maisonpara">
                We build highly scalable platforms and well-tested products that
                are ready to handle million of users on a daily basis.
              </p>
              <span className="vertical_text">website</span>
            </li>
            <li>
              <div className="maisonimghide">
                {/* <Img fluid={data.specialiteMobileImage.childImageSharp.fluid} /> */}
                <img src={specialiteMobile} alt="" />
              </div>
              <div className="maisonimgshow">
                {/* <Img fluid={data.specialiteMobileImage.childImageSharp.fluid} /> */}
                <img src={specialiteMobile} alt="" />
              </div>
              <h5 className="h5">Mobile Application Development</h5>
              <p className="maisonpara">
                iOS and Android apps built with React Native based on robust
                Node.js backend will satisfy every end-user.
              </p>
              <span className="vertical_text">mobile</span>
            </li>
            <li>
              <div className="maisonimghide">
                {/* <Img
                  fluid={data.specialiteDesktopImage.childImageSharp.fluid}
                /> */}
                <img src={specialiteDesktop} alt="" />
              </div>
              <div className="maisonimgshow">
                {/* <Img
                  fluid={data.specialiteDesktopImage.childImageSharp.fluid}
                /> */}
                <img src={specialiteDesktop} alt="" />
              </div>
              <h5 className="h5">Desktop Application Development</h5>
              <p className="maisonpara">
                Thanks to Electron JS, we build desktop applications for
                internal and external usage. Be like Slack who uses Electron JS
                for their desktop app.
              </p>
              <span className="vertical_text">desktop</span>
            </li>
            <li>
              <div className="maisonimghide">
                {/* <Img
                  fluid={data.specialiteBackendImage.childImageSharp.fluid}
                /> */}
                <img src={specialiteBackend} alt="" />
              </div>
              <div className="maisonimgshow">
                {/* <Img
                  fluid={data.specialiteBackendImage.childImageSharp.fluid}
                /> */}
                <img src={specialiteBackend} alt="" />
              </div>
              <h5 className="h5">Backend Development</h5>
              <p className="maisonpara">
                Node.js together with MongoDB/ArangoDB/ RabbitMQ and tools
                adjusted to your needs are the heart of our backends.
              </p>
              <span className="vertical_text">backend</span>
            </li>
          </ul>
          {/* <!-- <div className="wrapper amazing_technologies">
            <div className="tech_text">
                <h3 className="wrapper h3">
                    Our Services are driven by amazing Technologies
                </h3>
                <p className="wrapper">
                    Additionally, we build software using: ES6, Redux, Redis, Express, Typescript, Microservices, ArangoDB, RabbitMQ, Selenium,
                    Chai.js, Cucumber, Ansible, Consul, Gitlab, Gitlab CI, Flow Type.
                </p>
            </div>
            <div className="tech_logos">
                <ul>
                    <li>
                        <img src="images/electron-js.png" alt="" />
                    </li>
                    <li>
                        <img src="images/node-js.png" alt="" />
                    </li>
                    <li>
                        <img src="images/react-js.png" alt="" />
                    </li>
                    <li>
                        <img src="images/javascript.png" alt="" />
                    </li>
                    <li>
                        <img src="images/mongo-db.png" alt="" />
                    </li>
                </ul>
            </div>
        </div> --> */}
        </div>
      </section>
      {/* <section className="wrapper our_client_say">
        <h2 className="wrapper h2">Our clients say</h2>
        <div className="wrapper our_client_say_slider">
          <div className="colwrap">
            <span className="circle_img">
              <abbr>
                <Img fixed={data.profilePicImage.childImageSharp.fixed} />
                <img src={profilePic} alt="" />
              </abbr>
            </span>
            <p className="wrapper">
              It has been a pleasure working with you and seeing this project
              through from start to finish. Thank you guys for your high level
              of expertise, flexibility and creativity in approach and diligence
              in handling in addition to always being available, present and
              kind.
            </p>
            <strong className="wrapper">
              <abbr>Nikunj Mavani</abbr>
            </strong>
            <p className="wrapper m_top_bottom_0">Founder of Freelance</p>
          </div>
          <div className="colwrap slick-active">
            <span className="circle_img">
              <abbr>
                <Img fixed={data.profilePicImage.childImageSharp.fixed} />
                <img src={profilePic} alt="" />
              </abbr>
            </span>
            <p className="wrapper">
              It has been a pleasure working with you and seeing this project
              through from start to finish. Thank you guys for your high level
              of expertise, flexibility and creativity in approach and diligence
              in handling in addition to always being available, present and
              kind.
            </p>
            <strong className="wrapper">
              <abbr>Nikunj Mavani</abbr>
            </strong>
            <p className="wrapper m_top_bottom_0">Founder of Freelance</p>
          </div>
          <div className="colwrap">
            <span className="circle_img">
              <abbr>
                <Img fixed={data.profilePicImage.childImageSharp.fixed} />
                <img src={profilePic} alt="" />
              </abbr>
            </span>
            <p className="wrapper">
              It has been a pleasure working with you and seeing this project
              through from start to finish. Thank you guys for your high level
              of expertise, flexibility and creativity in approach and diligence
              in handling in addition to always being available, present and
              kind.
            </p>
            <strong className="wrapper">
              <abbr>Nikunj Mavani</abbr>
            </strong>
            <p className="wrapper m_top_bottom_0">Founder of Freelance</p>
          </div>
        </div>
        <ul className="slick-dots">
          <li className="slick-active">01</li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </section>
       */}
      <section className="wrapper our_projects">
        <div className="container">
          <h2 className="h2">Our projects make us proud</h2>
          {/* view all commented */}
          {/* <a href="javascript:void(0)">
            <span className="btn2">View All</span>
          </a> */}
        </div>
        <div className="container">
          <div className="wrapper colwrap_all">
            {/* {springs.map((props, i) => {
              console.log("props", props)
              return (
                <animated.div
                  key={`${props}_${i}`}
                  class="card"
                  onMouseMove={({ clientX: x, clientY: y }) =>
                    sets({ xys: calc(x, y) })
                  }
                  onMouseLeave={() => sets({ xys: [0, 0, 1] })}
                  style={{ transform: props.xys.interpolate(trans) }}
                >
                  <div className="colwrap bigcol">
                    <span className="imgwrap"></span>

                    <img src={portfolioImg1} alt="" />

                    <div className="project_info_text">
                      <h4 className="h4">Buzziro</h4>
                      <p>Branding WebDesigning WebDeveloping</p>
                    </div>
                  </div>
                </animated.div>
              )
            })} */}

            <div className="colwrap bigcol">
              <Tilt className="Tilt imgwrap" options={{ max: 25 }}>
                <span>
                  <img src={GrowImg} alt="" />
                </span>
              </Tilt>
              <div className="project_info_text">
                <h4 className="h4">Grow International</h4>
                <p>Web design/development</p>
              </div>
            </div>

            <div className="colwrap smallcol">
              <span className="imgwrap">
                <Tilt className="Tilt" options={{ max: 25 }}>
                  <img src={RenraccoonImg} alt="" />
                </Tilt>
              </span>
              <div className="project_info_text">
                <h4 className="h4">Renraccoon</h4>
                <p>Web design/development</p>
              </div>
            </div>

            <div className="colwrap smallcol">
              <Tilt className="Tilt imgwrap" options={{ max: 25 }}>
                <span>
                  <img src={BuzziroImg} alt="" />
                </span>
              </Tilt>
              <div className="project_info_text">
                <h4 className="h4">Buzziro</h4>
                <p>Web App Development</p>
              </div>
            </div>

            <div className="colwrap bigcol">
              <span className="imgwrap">
                <Tilt className="Tilt" options={{ max: 25 }}>
                  <img src={BukitvistaImg} alt="" />
                </Tilt>
              </span>
              <div className="project_info_text">
                <h4 className="h4">Bigrr - Bukitvista</h4>
                <p>Web app development</p>
              </div>
            </div>

            {/* <div className="colwrap bigcol">
              <span className="imgwrap">
                <Tilt className="Tilt" options={{ max: 25 }}>
                  <img src={portfolioImg1} alt="" />
                </Tilt>
              </span>
              <div className="project_info_text">
                <h4 className="h4">Buzziro</h4>
                <p>Branding WebDesigning WebDeveloping</p>
              </div>
            </div>
            <div className="colwrap smallcol">
              <span className="imgwrap">
                <Tilt className="Tilt" options={{ max: 25 }}>
                  <img src={portfolioImg2} alt="" />
                </Tilt>
              </span>
              <div className="project_info_text">
                <h4 className="h4">Paarcel</h4>
                <p>Branding WebDesigning WebDeveloping</p>
              </div>
            </div>
           */}
          </div>
          {/* view all commented */}
          {/* <div className="wrapper mobile_show">
            <a href="javascript:void(0)">
              <span className="btn2">View All</span>
            </a>
          </div> */}
        </div>
      </section>
    </Layout>
  )
}
export default IndexPage
